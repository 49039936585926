<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="MONGODB"
      :properties="properties"
      step-label="mongodb"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="MONGODB_ACTIONS"
        :default-value="properties.action"
        step-label="mongodb"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>
    <!-- Collection -->
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-collectionName`"
        v-model="properties.collectionName"
        outlined
        dense
        :label="$lang.labels.collectionName"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="mongodb-collectionName"
        @input="handleChange('collectionName', $event)"
      />
    </v-col>
    <!-- Query -->
    <text-input-with-editor-button
      v-if="['FIND', 'UPDATE', 'DELETE', 'COUNT'].includes(properties.action)"
      :key="`${step.id}-query`"
      :rules="[v => !!v || $lang.labels.required]"
      :default-value="properties.parameters.query"
      step-label="mongodb"
      required
      :readonly="!canEdit"
      @change="handleChange('parameters.query', $event)"
      @openEditor="$emit('openEditor', $event, 'query',
                         { text: 'query', value: properties.parameters.query, parentType: 'parameters' }
      )"
    />
    <!-- Query Update -->
    <text-input-with-editor-button
      v-if="['INSERT', 'UPDATE'].includes(properties.action)"
      :key="`${step.id}-queryUpdate`"
      :rules="[v => !!v || $lang.labels.required]"
      :default-value="properties.parameters.queryUpdate"
      :label="$lang.labels.queryUpdate"
      step-label="mongodb-queryUpdate"
      required
      :rows="1"
      :readonly="!canEdit"
      @change="handleChange('parameters.queryUpdate', $event)"
      @openEditor="$emit('openEditor', $event, 'queryUpdate',
                         { text: 'queryUpdate', value: properties.parameters.queryUpdate, parentType: 'parameters' }
      )"
    />
    <!-- Projection -->
    <text-input-with-editor-button
      v-if="properties.action === 'FIND'"
      :key="`${step.id}-projection`"
      :rules="[v => !!v || $lang.labels.required]"
      :default-value="properties.parameters.projection"
      step-label="mongodb-projection"
      :label="$lang.labels.projection"
      required
      :rows="1"
      :readonly="!canEdit"
      @change="handleChange('parameters.projection', $event)"
      @openEditor="$emit('openEditor', $event, 'projection',
                         { text: 'projection', value: properties.parameters.projection, parentType: 'parameters' }
      )"
    />

    <!-- Parameters - skip -->
    <v-col v-if="properties.action === 'FIND'" cols="12">
      <v-text-field
        :key="`${step.id}-skip`"
        v-model="properties.parameters.skip"
        outlined
        dense
        :label="$lang.labels.skip"
        :readonly="!canEdit"
        data-cy="mongodb-skip"
        @input="handleChange('parameters.skip', $event)"
      />
    </v-col>
    <!-- Parameters - limit -->
    <v-col v-if="properties.action === 'FIND'" cols="12">
      <v-text-field
        :key="`${step.id}-limit`"
        v-model="properties.parameters.limit"
        outlined
        dense
        :label="$lang.labels.limit"
        :readonly="!canEdit"
        data-cy="mongodb-limit"
        @input="handleChange('parameters.limit', $event)"
      />
    </v-col>
    <!-- Parameters - sort field -->
    <v-col v-if="properties.action === 'FIND'" cols="12">
      <v-text-field
        :key="`${step.id}-sortField`"
        v-model="properties.parameters.sort.field"
        outlined
        dense
        :label="$lang.labels.sortField"
        :readonly="!canEdit"
        data-cy="mongodb-sort-field"
        @input="handleChange('parameters.sort.field', $event)"
      />
    </v-col>
    <!-- Parameters - sort direction -->
    <v-col v-if="properties.action === 'FIND'" cols="12">
      <v-text-field
        :key="`${step.id}-sortDirection`"
        v-model="properties.parameters.sort.direction"
        outlined
        dense
        :label="$lang.labels.sortDirection"
        :readonly="!canEdit"
        data-cy="mongodb-sort-direction"
        @input="handleChange('parameters.sort.direction', $event)"
      />
    </v-col>

    <target-object-field
      :key="`${step.id}-targetObject`"
      :show-single-result-toggle="properties.action === 'FIND'"
      :default-target-object="properties.targetObject"
      :default-single-result="properties.singleResult"
      step-label="mongodb"
      required
      :readonly="!canEdit"
      @change="handleChange($event.key, $event.value)"
    />
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect,
  TextInputWithEditorButton,
  TargetObjectField
} from './components'
import StepMixin from './StepMixin'

import { getNestedPropertyForSetting } from '@/utils/helpers'
import { MONGODB_ACTIONS } from './constants'

export default {
  name: 'MongodbStep',
  components: {
    ActionSelect,
    CredentialsSelect,
    TextInputWithEditorButton,
    TargetObjectField
  },
  mixins: [StepMixin],
  data() {
    return {
      MONGODB_ACTIONS
    }
  },
  created() {
    if (!this.properties.parameters) {
      this.$set(this.properties, 'parameters', {
        query: null,
        queryUpdate: null,
        sort: {
          field: '',
          direction: ''
        },
        skip: 0,
        limit: 0,
        projection: ''
      })
    }
  },
  methods: {
    handleChange(key, val) {
      if (key === 'action') {
        if (this.properties?.parameters) {
          this.$set(this.properties.parameters, 'query', null)
          this.$set(this.properties.parameters, 'queryUpdate', null)
        } else {
          this.$set(this.properties, 'parameters', {
            query: null,
            queryUpdate: null
          })
        }
      }

      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }

      this.$set(this.properties, key, val)
    }
  }
}
</script>
